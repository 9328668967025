<template>
  <div class="account">
    <div class="department-name">主管理员</div>
    <div class="table-wrapper">
      <el-table
        cell-class-name="custom-table-cell"
        header-row-class-name="custom-table-header"
        :data="state.list"
        empty-text="暂无数据"
        style="width: 100%"
        v-loading="state.loadingState"
      >
        <el-table-column align="left" show-overflow-tooltip prop="name" label="姓名">
          <template #default="scope">
            <wxview type="userName" :openid="scope.row.name">{{ scope.row.name }}</wxview>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="department" label="部门">
          <template #default="scope">
            <el-popover placement="right-end" popper-class="dark-popover" :width="220" trigger="hover">
              <div>
                <template v-for="(item, index) in scope.row.departmentName" :key="item">
                  <span type="departmentName" :openid="item">{{ item }}</span>
                  <template v-if="index !== scope.row.departmentName.length - 1">，</template>
                </template>
              </div>
              <template #reference>
                <div class="ellipsis column-popover">
                  <template v-for="(item, index) in scope.row.departmentName" :key="item">
                    <span type="departmentName" :openid="item">{{ item }}</span>
                    <template v-if="index !== scope.row.departmentName.length - 1">，</template>
                  </template>
                </div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="positionName" label="职位"></el-table-column>
        <el-table-column align="left" prop="mobile" label="手机"></el-table-column>
        <el-table-column align="left" prop="area" label="管理范围">
          <template #default>全部</template>
        </el-table-column>
        <el-table-column align="left" label="操作" v-if="isAdmin">
          <template #default="{ row }">
            <el-button size="mini" type="text" @click="onTransferClick(row)">转让</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <!-- 转让管理员 -->
  <el-dialog
    v-model="state.showTransfer"
    custom-class="custom-dialog"
    title="转让创建人身份"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="472px"
    :append-to-body="true"
  >
    <div class="tip" v-if="transferForm.step === 1">
      <span class="iconfont el-icon-warning"></span>
      修改完成后，绑定的安全手机号都会修改
    </div>
    <div class="form-wrap">
      <el-form ref="verifyFormRef" size="small" label-width="108px" :model="transferForm">
        <template v-if="transferForm.step === 1">
          <el-form-item label="当前主管理员">{{ transferForm.current?.name }}</el-form-item>
          <el-form-item label="绑定手机号码" prop="mobile">
            <el-input
              size="small"
              :disabled="true"
              :model-value="`+86-${mobileNumberChange(transferForm.current.mobile)}`"
            ></el-input>
            <el-button class="btn-get-code" type="text" @click="getCode">
              {{ time === 60 ? '获取验证码' : `${time}秒后重发` }}
            </el-button>
          </el-form-item>
          <el-form-item label="验证码">
            <el-input size="small" v-number maxlength="6" v-model="transferForm.code"></el-input>
          </el-form-item>
          <div class="footer-buttons">
            <el-button type="primary" :disabled="!transferForm.code" size="small" @click="handleValid">
              下一步
            </el-button>
          </div>
        </template>
        <template v-else>
          <el-form-item label="新的管理员">
            <user-select v-model="transferForm.target" :single="true" placeholder="选择新管理员"></user-select>
          </el-form-item>
          <div class="next-footer">
            <el-button
              type="primary"
              :loading="transferForm.loading"
              :disabled="!transferForm.target.length"
              size="small"
              @click="onSubmit"
            >
              确认
            </el-button>
            <el-button size="small" @click="onCancel">取消</el-button>
          </div>
        </template>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
import { defineComponent, reactive } from 'vue'
import { useLogout, useVerifyCode } from '@/hooks/sys'
import { checkedSms, getAdminList, sendSms, transferAdmin } from '@/apis'
import { mobileNumberChange } from '@/common/utils/util'
import UserSelect from '@/components/user-select'
import { ElMessage } from 'element-plus'
import { useIsAdmin } from '@/hooks'
export default defineComponent({
  name: 'accountMain',
  components: { UserSelect },
  setup() {
    const state = reactive({
      showTransfer: false,
      list: [],
      loadingState: false
    })
    const transferForm = reactive({
      step: 1,
      current: {},
      mobile: '',
      code: '',
      loading: false,
      target: []
    })
    const initAdminList = async () => {
      state.loadingState = true
      try {
        const result = await getAdminList()
        state.list = result.data || []
        state.loadingState = false
      } catch (error) {
        state.loadingState = false
        console.log('error', error)
      }
    }
    initAdminList()
    /**
     * 获取手机验证码
     */
    const getVerifyCode = () => {
      if (!transferForm.current.mobile) {
        ElMessage.error('手机号码不能为空')
        return Promise.reject(new Error('mobile is required'))
      }
      return sendSms(transferForm.current.mobile)
    }
    const { time, getCode } = useVerifyCode(getVerifyCode)
    const onTransferClick = row => {
      transferForm.current = row
      transferForm.step = 1
      state.showTransfer = true
    }
    const handleValid = async () => {
      try {
        await checkedSms({
          code: transferForm.code
        })
        transferForm.step = 2
      } catch (error) {
        ElMessage.error(error.message)
      }
    }
    const onCancel = () => {
      state.showTransfer = false
    }
    const onSubmit = async () => {
      transferForm.loading = true
      const params = {
        oldUserId: transferForm.current.id,
        newUserId: transferForm.target[0]
      }
      const doLogout = useLogout(false)
      try {
        await transferAdmin(params)
        ElMessage.success('转让成功,请重新登录')
        await doLogout()
        state.showTransfer = false
      } catch (error) {
        ElMessage.error('转让管理员失败')
      } finally {
        transferForm.loading = false
      }
    }
    const isAdmin = useIsAdmin()
    return {
      state,
      transferForm,
      time,
      getCode,
      onSubmit,
      onTransferClick,
      mobileNumberChange,
      handleValid,
      onCancel,
      isAdmin
    }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
